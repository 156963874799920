import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link
                href="https://open.spotify.com/show/780Lm9dHOmKEDu8mlwQPgc?si=622c5acb82ce45e9"
                target="_blank"
                rel="noreferrer"
              > My Podcast
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://jatinp.notion.site/Jatin-Pandya-Speaker-Profile-e0710b12669e4db1a7bd53835be464a8?pvs=4"
                target="_blank"
                rel="noreferrer"
              > Speaker Profile
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://jatinp.notion.site/Hey-there-I-m-Jatin-fe773911d3ab4161a59333c89a2b180a?pvs=4"
                target="_blank"
                rel="noreferrer"
              > My Experience
              </Nav.Link>
            </Nav.Item>            
            <Nav.Item>
              <Nav.Link
                href="https://topmate.io/jatin_pandya"
                target="_blank"
                rel="noreferrer"
              > Book a call
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;