import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Call Me <span className="purple"> Jay </span> But Who Am I?
            </h1>
            <p className="home-about-body">
            Passionate about technology and making a positive impact on the community.
              <br />
              <br />I am a results-oriented 
              <i>
                <b className="purple"> Developer Relations Manager with expertise in Developer Product Management, Product Growth, and User Experience</b>
              </i>
              <br />
              <br />
              I have a proven track record of  
              <i>
                <b className="purple"> driving developer adoption, organizing successful Developer Initiatives </b> and {" "}
                <b className="purple">
                executing impactful Product Growth Marketing Strategies.
                </b>
              </i>
              <br />
              <br />
              With a focus on continuous improvement, I thrive on conducting <b className="purple">User Research, leading UX design, and Collaborating with cross-functional teams 
</b>  to deliver
              <i>
                <b className="purple">
                  {" "}
                  Innovative products and solutions.
                </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>My Virtual Existance is on</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/Jpandya26"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/jatinjpnd268"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/jpandya26"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
